import React, { useState, useEffect } from 'react';
import { signOut, getAuth } from "firebase/auth";
import './sidebar.css';
import Logo from '../../assets/img/PositiveLogoSvg.svg';
import LogoP from '../../assets/img/P-mulher.png';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faTv, faLock, faGear, faUserPlus, faUsers, faRightFromBracket, faHouse, faChevronUp, faChevronDown, faChartPie } from '@fortawesome/free-solid-svg-icons';
import checkUserAccess from '../../routes/checkUserAccess'

function Sidebar({ isOpen }) {
  const auth = getAuth();
  const [activeItem, setActiveItem] = useState(null);

  const [userAccess, setUserAccess] = useState({});

  const location = useLocation();
  const isCurrentPage = (path) => location.pathname === path;

  useEffect(() => {
    const verifyAccess = async () => {
      try {
        const isMaster = await checkUserAccess("userMasters");
        const isPremium = await checkUserAccess("userPremium");
        const isBasic = await checkUserAccess("userBasic");

        setUserAccess({
          isMaster,
          isPremium,
          isBasic,
        });
      } catch (error) {
        console.error("Erro ao verificar acesso:", error);
      }
    };

    verifyAccess();
  }, []);

  const toggleItem = (itemId) => {
    if (activeItem === itemId) {
      setActiveItem(null);
    } else {
      setActiveItem(itemId);
    }
  };

  async function deslogar(e) {
    e.preventDefault();
    await signOut(auth)
    localStorage.removeItem("@detalheUsuario");
    localStorage.removeItem("chatMessages")
  }

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="logo-details">
        <img src={isOpen ? Logo : LogoP} alt='Logo'></img>
      </div>

      {/* MOSTRAR TODAS AS  lis para perfilmaster */}
      <ul className="nav-list">
        <li className={isCurrentPage('/home') ? 'active-li' : ''}>
          <Link to="/home">
            <FontAwesomeIcon icon={faHouse} className='i' />
            <span className="links_name">Home</span>
          </Link>
          <span className="tooltip">Home</span>
        </li>
        <li className={isCurrentPage('/encontros') ? 'active-li' : ''}>
          <div onClick={() => toggleItem(1)}>
            <FontAwesomeIcon icon={faUsers} className='i' />
            <span className="links_name">Encontros</span>
            <FontAwesomeIcon
              icon={activeItem === 1 ? faChevronUp : faChevronDown}
              id='hide-icon'
              className='i'
            />
          </div>
          <span className="tooltip">Encontros</span>
          {activeItem === 1 && (
            <ul id='div-map'>
              <li>
                <Link id='a-map' to="/encontros">Vídeos</Link>
              </li>
              <li>
                <Link id='a-map' to="/albuns">Fotos</Link>
              </li>
            </ul>
          )}
        </li>

        <li>
          <Link to="/conteudos">
            <FontAwesomeIcon icon={faTv} className='i' />
            <span className="links_name">Conteúdos</span>
            <span className="tooltip">Conteúdos</span>
          </Link>
        </li>

        {/* Roda MPM (para Master e Premium) */}
        {(userAccess.isMaster || userAccess.isPremium) ? (
          <li>
            <Link to="/roda-da-mulher-positiva">
              <FontAwesomeIcon icon={faChartPie} className='i' />
              <span className="links_name">Roda MPM</span>
              <span className="tooltip">Roda.MPM</span>
            </Link>
          </li>
        ) : (
          (userAccess.isBasic) ? (
            <li>
              {/* Adicionando li específica para userBasic */}
              <Link to="https://api.whatsapp.com/send?phone=5586981563557&text=Ol%C3%A1,%20Quero%20um%20Upgrade%20no%20meu%20Plano%20Positive-se%20Mulher" target='_blanck'>
                <FontAwesomeIcon icon={faChartPie} className='i' />
                <FontAwesomeIcon style={{ fontSize: 10, marginLeft: -15 }} icon={faLock} className='i' />
                <span className="links_name">Roda MPM (Premium)</span>
                <span className="tooltip">Roda.MPM (Premium)</span>
              </Link>
            </li>
          ) : null
        )}
        <li>
          <Link to="/ranking">
            <FontAwesomeIcon icon={faMedal} className='i' />
            <span className="links_name">Ranking</span>
            <span className="tooltip">Ranking</span>
          </Link>
        </li>

        {/* Cadastro e Administrador (somente para Master) */}
        {userAccess.isMaster ? (
          <>
            <li>
              <Link to="/registrar">
                <FontAwesomeIcon icon={faUserPlus} className='i' />
                <span className="links_name">Cadastro</span>
                <span className="tooltip">Cadastro</span>
              </Link>
            </li>
            <li>
              <Link to="/administrador">
                <FontAwesomeIcon icon={faGear} className='i' />
                <span className="links_name">Administrador</span>
                <span className="tooltip">Administrador</span>
              </Link>
            </li>
          </>
        ) : null}
        <li className="profile">
          <span>Sair</span>
          <button id="log_out" onClick={deslogar}><FontAwesomeIcon className='i-logout' icon={faRightFromBracket} /></button>
        </li>
      </ul>
    </div>
  );
}


export default Sidebar;
