import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import RoutesApp from "./routes";
import Notification from "./components/Notification";
import Chat from "./components/Chat";

function App() {
  const [notification, setNotification] = useState(null);

  return (
    <BrowserRouter className="container">
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <RoutesApp setNotification={setNotification} />
      {/* Componente de chat flutuante */}
      <Chat />
    </BrowserRouter>

  );
}

export default App;
