import React, { useState, useEffect } from 'react';
import './chat.css';
import axios from 'axios';

const Chat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState(() => {
    // Recupera mensagens do Local Storage ao carregar
    const savedMessages = localStorage.getItem('chatMessages');
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  

  // Verifica se o usuário está logado
  const isUserLoggedIn = Boolean(localStorage.getItem('@detalheUsuario'));

  // Atualiza o Local Storage sempre que as mensagens mudarem
  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  const systemMessage = {
    role: 'system',
    content: `
      Você é a PositivIA, uma assistente virtual dedicada a ajudar mulheres empreendedoras na plataforma Portal das Positivas. 
      Responda de forma clara, objetiva e gentil, chamando as usuárias de "Positiva" ocasionalmente.

      - Para perguntas sobre conteúdo de empreendedorismo, informe que o portal oferece vários cursos e mencione a mentora Ana Paula Bacelar, mentora e educadora empresárial, que está disponível para orientação.
      - Para dúvidas específicas, peça que entrem em contato pelo e-mail: contato@positiveempresarial.com.br.
      - Sobre o portal, informe que no menu lateral estão as seguintes seções:
        - **Encontros:** Fotos e vídeos dos eventos.
        - **Conteúdo:** Cursos e aulas disponíveis.
        - **Roda da Mulher Positiva:** Ferramenta para preencher e avaliar os 12 pilares da vida.
        - **Ranking:** Lista das mulheres mais ativas no portal.
        - **Home:** Card de boas-vindas com link para vídeo e instruções do portal.
      Se não souber a resposta, oriente a usuária a entrar em contato com o suporte.
    `,
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    // Inclui a mensagem de sistema
    const updatedMessages = [
      systemMessage,
      ...messages.slice(-5), // Envia apenas as 5 últimas mensagens do histórico
      userMessage,
    ];
    setMessages([...messages, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: updatedMessages,
          max_tokens: 200,
          temperature: 0.5,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const botMessage = {
        role: 'assistant',
        content: response.data.choices[0].message.content,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Erro ao se comunicar com a API:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'Desculpe, algo deu errado. Tente novamente mais tarde.',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  // Não renderiza o chat se o usuário não estiver logado
  if (!isUserLoggedIn) {
    return null;
  }

  return (
    <div>
      {/* Ícone flutuante com nome */}
      <div className="chat-floating-icon-wrapper" onClick={() => setIsOpen(!isOpen)}>
        <span className="chat-floating-label">Fale com a Positiv<span className="highlight-ia">IA</span></span>
        <div className="chat-floating-icon">💬</div>
      </div>

      {/* Modal do chat */}
      {isOpen && (
        <div className="chat-modal">
          <div className="chat-header">
            <h3>Positiv<span className="highlight-ia2">IA</span></h3>
            <button className="chat-close-btn" onClick={() => setIsOpen(false)}>X</button>
          </div>
          <div className="chat-body">
            <div className="chat-messages">
              {messages.map((msg, index) => (
                <div key={index} className={`chat-message ${msg.role}`}>
                  <strong>
                    {msg.role === 'user' ? <span className="user-name">Você</span> : 'Positiv'}
                    {msg.role === 'assistant' && <span className="highlight-ia">IA</span>}:
                  </strong>
                  <p className={`message-text ${msg.role}`}>
                    {msg.content}
                  </p>
                </div>
              ))}
              {loading && <p className="typing-indicator">Digitando...</p>}
            </div>
          </div>
          <div className="chat-footer">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Como posso te ajudar Positiva?..."
            />
            <button onClick={handleSendMessage} disabled={loading}>
              Enviar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;